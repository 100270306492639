import React from 'react';

const Modal = (props) => {
    return (
        <div>
            <div dangerouslySetInnerHTML={modalTextHtml(props.modalText)} />
        </div>
        )
}

export default Modal;

const modalTextHtml = (txt) => {
    return { __html: txt };
}