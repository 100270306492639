import responseText from ".././data/response.json"
import adBanner from ".././data/ad_banners.json"

const calculate = (calcValues, formValues) => {
    
    const driversLicense = calcValues.driversLicense;
   
    const sub_bsv = 2000;  // banner subaru outback predefined car
    const sub_btj = 1671;
    const sub_bto = 2100;

    const val_bsv = Number(calcValues.val_bsv); // bil max släpvagnsvikt (vad bilen får dra släp fullt lastad)
    const val_btj = Number(calcValues.val_btj); // bil tjänstevikt (bilens vikt utan last -endast förare)
    const val_bto = Number(calcValues.val_bto); // bil totalvikt (bilens vikt fullt lastat)
    const val_btv = Number(calcValues.val_btv); // bil max tågvikt (fordonets och släpvagnens sammanlagda bruttovikt, brukar vara bilens totalvikt + max släpvagnsvikt.)
    const val_sto = Number(calcValues.val_sto); // släp totalvikt (släpets vikt fullt lastat)
    const val_stj = Number(calcValues.val_stj); // släp tjänstevikt (släpets vikt utan last)

    let [feedback, calculatedTrailerLoad, calculatedCarWeight, calculatedGto, responseId] = ''
    let [subarucar, calculatedSubaruLoad, calculatedSubaruWeight, subaruCalculatedGto, subaruResponseId] = ''
    let msgBanner = "";
    let bannerId = 0;

    if (driversLicense === "val_kb" || driversLicense === "val_kbu") {

        [feedback, calculatedTrailerLoad, calculatedCarWeight, calculatedGto, responseId] = licenseB(val_bsv, val_btj, val_bto, val_btv, val_sto, val_stj);
        [subarucar, calculatedSubaruLoad, calculatedSubaruWeight, subaruCalculatedGto, subaruResponseId] = licenseB(sub_bsv, sub_btj, sub_bto, val_btv, val_sto, val_stj);
               
    } else {
        if (driversLicense === "val_kbe" || driversLicense === "val_kbeg") {
           
            [feedback, calculatedTrailerLoad, calculatedCarWeight, calculatedGto, responseId] = licenseBE(val_bsv, val_btj, val_bto, val_btv, val_sto, val_stj);
            [subarucar, calculatedSubaruLoad, calculatedSubaruWeight, subaruCalculatedGto, subaruResponseId] = licenseBE(sub_bsv, sub_btj, sub_bto, val_btv, val_sto, val_stj);
        }
    }
    

    if (calculatedSubaruLoad > 0 && calculatedSubaruLoad > calculatedTrailerLoad) {
        bannerId = 1;

        msgBanner = getAddBannerText(bannerId, [["%calculatedSubaruLoad%", calculatedSubaruLoad]]);

    } else {
        bannerId = 2;
        // Show generic add, the Subarus value is same or not as good as entered car

        const max_possible_bsv = (3500 - sub_bto > 3500 - sub_bsv) ? 3500 - sub_bsv : 3500 - sub_bto
        const m_weight = (driversLicense === "val_kb") ? (max_possible_bsv) : sub_bsv;
       
        msgBanner = getAddBannerText(bannerId, [["%m_weight%", m_weight]]);
    }

    return [feedback, msgBanner, bannerId, responseId, calculatedTrailerLoad, calculatedSubaruLoad];

    function licenseB(val_bsv, val_btj, val_bto, val_btv, val_sto, val_stj)
	{
        // get response for driverslicense B(KB) or B-utökad(KBU)
        let returnMsg;
        let responseAllowed = false;
        let responseId = 0;

        const gto = (driversLicense === "val_kb") ? 3500 : 4250;
        const max_gto = (val_sto > 750 && driversLicense === "val_kb") ? 3500 : 4250;
        const carWeight = max_gto - val_sto;
        const maxTrailerLoad = val_sto - val_stj;
        const min_bto = 499; // minimum car weight (when not entered)
        let trailerLoad = 0;
        let carBUnotAllowed = false;
        let max_sto;


        if (val_bto === 0 || val_sto === 0 )
		{
            // Car and Trailer not entered
            responseAllowed = true;
            responseId = 1;
        }
		else
		{
			if(val_bto === 0 && val_sto > 0)
			{
				// only Trailer entered
				if(val_sto < (gto - min_bto))
				{
					responseAllowed = true;
					responseId = 4;
				} else
				{
					responseAllowed = false;
					responseId = 107;
				}

			}

			if(val_bto > 0 && val_sto === 0)
			{
				// only Car entered
				let carBnotAllowed = false;


				if(driversLicense === "val_kb")
				{
					if(val_bto > 3500)
					{
						carBnotAllowed = true;
					}
					else
					{
						max_sto = 750;
					}
				}
				else
				{
					// driverLicense: B Utökat (kbu)
					const maxPossible_sto = 4250 - val_bto;
					max_sto = (maxPossible_sto > val_bsv) ? val_bsv : maxPossible_sto;

					if(max_sto < 0)
					{
						carBUnotAllowed = true;
					}
				}

				if(carBnotAllowed === true)
				{
					responseAllowed = false;
					responseId = 104;
				}
				else
				{
					if(carBUnotAllowed === true)
					{
						responseAllowed = false;
						responseId = 105;
					}
					else
					{
						responseAllowed = true;
						responseId = 9;
					}
				}
			}

			if (val_bto > 0 && val_sto > 0) {
				// both Car and Trailer entered

				if (((max_gto >= (val_bto + val_sto)) || (max_gto - (val_bto + val_stj) > 0)) && (val_sto > val_stj) ) {

					// total weight ok

					if (val_sto <= 750)
					{
						responseAllowed = true;
						responseId = 2;
					}
					else
					{
						if(val_bsv > val_stj)
						{
							if(max_gto >= (val_bto + val_stj))
							{
								if(val_sto <= val_bsv)
								{
									const maxPossible_sto = max_gto - val_bto;
                                
									max_sto = (maxPossible_sto > val_bsv) ? val_bsv : maxPossible_sto;
									trailerLoad = ((max_sto > val_sto) ? val_sto : max_sto) - val_stj;

									responseAllowed = true;
									responseId = 3;
								} else {
									//responseAllowed = false;  // Detta blev fel i andra case!!!
									//responseId = 108;

									const maxPossible_sto = max_gto - val_bto;
									max_sto = (maxPossible_sto > val_bsv) ? val_bsv : maxPossible_sto;
									trailerLoad = ((max_sto > val_sto) ? val_sto : max_sto) - val_stj;

									responseAllowed = true;
									responseId = 3;
								}

							}
							else
							{
								responseAllowed = false;
								responseId = 101;
							}
						}
						else
						{
							responseAllowed = false;
							responseId = 103;
						}
					}

                
				} else {
               
						responseAllowed = false;
						responseId = 101;
              
				}
			}

			if(val_sto > 750)
			{
				if(((val_sto !== 0) && (val_sto <= val_stj)) || // släpets tjänstevikt är högre än totalvikt
					(val_sto + val_bto > gto)) // totalvikt större än maxvikt för körkortstypen
				{
				
					responseAllowed = false;
					responseId = 107;
				}
			}
		}

		let returnLoad = 0;
       
		switch (responseId) {
			case 1:
				returnMsg = getResponse(responseId, [["%gto%", gto]]);
				break;
			case 2:
				returnMsg = getResponse(responseId, [["%val_sto-val_stj%", val_sto - val_stj]]); 
				returnLoad = val_sto - val_stj;
              
				break;
			case 3:
				returnMsg = getResponse(responseId, [["%trailerLoad%", trailerLoad]]);
				returnLoad = trailerLoad;
				break;
			case 4:
				returnMsg = getResponse(responseId, [["%maxTrailerLoad%", maxTrailerLoad], ["%carWeight%", carWeight]]);
				returnLoad = maxTrailerLoad;
				break;
          
			case 9:
				returnMsg = getResponse(responseId, [["%gto-val_bto%", gto - val_bto]]);
				break;

			case 101:
				returnMsg = getResponse(responseId, [["%gto%", gto]]);
				break;

			default:
				returnMsg = getResponse(responseId, []);
		}
        
//        return [returnMsg, returnLoad, carWeight, gto, responseId] ;
        return [returnMsg, maxTrailerLoad, carWeight, gto, responseId] ;
    }

    function licenseBE(val_bsv, val_btj, val_bto, val_btv, val_sto, val_stj) {
        // Get response for driverslicense BE(KBE) or BE-before130119(KBEG)
         
        let returnMsg;

        let responseAllowed = false;
        let responseId = 0;
        const carWeight = 3500 - val_sto;
        const gto = (driversLicense === "val_kbe") ? 7000 : 100000;


        if (val_bto === 0) {
            // Car and trailer not entered, or only trailer entered

            if (driversLicense === "val_kbe") {
                if (val_sto > 3500) {
                    responseAllowed = false;
                    responseId = 102;
                } else {
                    responseAllowed = true;
                    responseId = 5;
                    
                    if (val_sto > 0) {
                        responseAllowed = true;
                        responseId = 6;                        
                    }
                }
                
            } else {
                responseAllowed = true;
                responseId = 7;
            }

           
        }

        if (val_bto > 0 && val_sto === 0) {
            // only Car entered

            if (val_bto > 3500) {
                responseAllowed = false;
                responseId = 106;
            } else {
                responseAllowed = true;
                responseId = 8;
            }

        }


        const gbt = 3500;
        const gst = (driversLicense === "val_kbe") ? 3500 : 100000;

        if (val_bto > 0 && val_sto > 0) {
            // both Car and Trailer entered
           
            if (gbt >= val_bto && gst >= val_sto) {
                if (val_bsv > val_stj) {
                    if (val_sto < val_bsv) {
                        responseAllowed = true;
                        responseId = 2;
                    } else {
                        responseAllowed = true;
                        responseId = 3;
                    }
                } else {
                    responseAllowed = false;
                    responseId = 103;
                }
            } else {
                responseAllowed = false;
                responseId = 102;
            }

        }

        if ((val_sto !== 0) && (val_sto <= val_stj)) {
            // släpets tjänstevikt är högre än totalvikt
            responseAllowed = false;
            responseId = 107;
        }

        let returnLoad = 0;
        switch (responseId) {
            case 2:
                returnMsg = getResponse(responseId, [["%val_sto-val_stj%", val_sto - val_stj]]);
                returnLoad = (val_sto - val_stj);
                break;
            case 3:
                returnMsg = getResponse(responseId, [["%trailerLoad%", val_bsv - val_stj]]);
                returnLoad = (val_bsv - val_stj);
                break;
            case 5:
                returnMsg = getResponse(responseId, []);
                break;
            case 6:
                returnMsg = getResponse(responseId, [["%val_sto-val_stj%", val_sto - val_stj]]);
                returnLoad = (val_sto - val_stj);
                break;
            case 7:
                returnMsg = getResponse(responseId, []);
                returnLoad = (val_sto - val_stj);
                break;
            case 8:
                returnMsg = getResponse(responseId, [["%val_bsv%", val_bsv]]);
                break;
            case 102:
                returnMsg = getResponse(responseId, []);
                break;
            case 103:
                returnMsg = getResponse(responseId, []);
                break;
            case 106:
                returnMsg = getResponse(responseId, []);
                break;
            case 107:
                returnMsg = getResponse(responseId, []);
                break;

            default:
            //
        }
        return [returnMsg, returnLoad, carWeight, gto, responseId];
    }


    function getResponse(responseId, arr) {

        const resp = responseText.response.filter(item => { if (item.id === responseId) return item });
        let respTxt = resp[0].text;
        
        for (let a in arr) {
            const stra = String(arr[a][0]);
            const strb = String(arr[a][1]);
            respTxt=respTxt.replace(stra, strb); 
        }
        
        return respTxt;
        
    }

    function getAddBannerText(id, arr) {
        const resp = adBanner.banners.filter(item => { if (item.id === id) return item });
        let respTxt = resp[0].text;

        for (let a in arr) {
            const stra = String(arr[a][0]);
            const strb = String(arr[a][1]);
            respTxt = respTxt.replace(stra, strb);
        }

        return respTxt;

    }
}

export default calculate;