import React from 'react';
import useForm from "./useForm";
import Result from "./result";
import Email from "./email";

import Banner from "./banner";
import Modalmessage from "./modalMessage";

import predefinedTrailers from ".././data/predefinedtrailers.json";

const Form = () =>
{

	const { formValues, calcValues, handleFormChange, handleMultiOptionsChange, handleSubmit, showModalMessage, resetApp, viewResult } = useForm(
		{
			carLicenseplate: '',
			carModel: '',
			carLicenseValidated: false,
			carLicenseInvalid: false,
			trailerLicenseplate: '',
			trailerModel: '',
			trailerLicenseValidated: false,
			carLicenseInvalid: false,
			resultContent: '',
			entered_sto: '',
			entered_stj: '',
			bannerId: 1,
			bannerTxt: '',
			modalMsgId: 0,
			modalTxt: '',
			responseId: 0,
			calculatedTrailerLoad: 0,
			calculatedSubaruLoad: 0,
			trailerBy: '',
			hasResult: false
		},
		{
			driversLicense: "val_kb",
			val_bto: 0,
			val_btj: 0,
			val_bsv: 0,
			val_btv: 0,
			val_sto: 0,
			val_stj: 0,
			maxWeightNoBreaks: 0
		});


	const images = require.context('.././img', true)

	const closeModalMessage = () =>
	{
		const modalWin = document.getElementById('modalMessageId');
		if(modalWin)
		{
			modalWin.style.display = "none"
		}
	}


	return (
		<div>
			<form id="allForm" onSubmit={handleSubmit} name="formAll">

				<div className="container-fluid bgGrey">
					<div className="container narrow sections">
						<div className={formValues.carLicenseValidated ? (formValues.carLicenseInvalid ? "field invalid" : "field valid") : "field"}>
							<label>Bilens registreringsnummer<small>(vänligen notera att tjänsten gäller för personbilar)</small></label>
							<input type="text" name="carLicenseplate" maxLength="6" onChange={handleFormChange} className="inputTxt inputReg" value={formValues.carLicenseplate} placeholder="ex. ABC233" />
							<span className="error">Ogiltigt reg. nummer</span>
						</div>

						<div className={formValues.trailerLicenseValidated ? (formValues.trailerLicenseInvalid ? "field invalid" : "field valid") : "field"}>
							<label>Släpet<small>Du kan ange uppgifter om släpet på tre olika sätt. <strong>Välj ett av alternativen.</strong></small></label>

							<ol className="multioptions">
								<li>
									<div className="option"><label><input type="radio" name="selectTrailer" value="byType" checked={formValues.trailerBy == 'byType'} onChange={handleMultiOptionsChange} /><span></span><span>Ange typ av släp</span></label></div>

									{formValues.trailerBy == 'byType' ? (
										<div className="panel radioBtns">
											{predefinedTrailers.data.map(function(trailer, index)
											{
												return (
													<div>
														<input type="radio" id={trailer.trailerid} name="trailer" onChange={handleFormChange} value={trailer.trailerid} />
														<label htmlFor={trailer.trailerid} className="trailerBtn">
															<div className="radioBtnImg"><img src={images('./icons/' + trailer.icon)} /></div>
																<div className="radioTextWrapper">
																	<div className="radioBtnHead">{trailer.trailer_name}</div>
																	<div className="radioBtnTxt">Totalvikt:{trailer.sto} kg
																</div>
															</div>
														</label>
													</div>
												)
											})}
										</div>) : null}
								</li>

								<li>
									<div className="option"><label><input type="radio" name="selectTrailer" value="byRegNo" checked={formValues.trailerBy == 'byRegNo'} onChange={handleMultiOptionsChange} /><span></span>Fyll i släpets registreringsnummer</label></div>

									{formValues.trailerBy == 'byRegNo' ? (
										<div className="panel">
											<input type="text" name="trailerLicenseplate" maxLength="6" className="inputTxt inputReg" onChange={handleFormChange} value={formValues.trailerLicenseplate} placeholder="ex. ABC233" />
											<span className="error">Ogiltigt reg. nummer</span>
										</div>) : null}
								</li>

								<li>
									<div className="option"><label><input type="radio" name="selectTrailer" value="byData" checked={formValues.trailerBy == 'byData'} onChange={handleMultiOptionsChange} /><span></span>Fyll i släpets tjänstevikt och totalvikt</label></div>

									{formValues.trailerBy == 'byData' ? (
										<div className="panel">
											<div className="row field">
												<div className="col-md-6 px-0 pl-md-0 pr-md-1 pb-1 md-pb-0">
													<input type="number" name="entered_stj" className="inputTxt" onChange={handleFormChange} value={formValues.entered_stj} placeholder="Tjänstevikt XXX kg" />
												</div>

												<div className="col-md-6 px-0 pl-md-1 pr-md-0 md-px-0 pb-1 md-pb-0">
													<input type="number" name="entered_sto" className="inputTxt" onChange={handleFormChange} value={formValues.entered_sto} placeholder="Totalvikt XXX kg" />
												</div>
											</div>
										</div>) : null}
								</li>
							</ol>
						</div>
					</div>
				</div>


				<div className="container-fluid bgWhite">
					<div className="container narrow sections">
						<div className="field">
							<label>Välj körkortstyp</label>

							<div className="radioBtns">
								<input type="radio" id="val_kb" name="driversLicense" onChange={handleFormChange} value="val_kb" />
								<label htmlFor="val_kb" className="driverlicenseBtn">
									<div className="radioBtnImg"><img src={images('./icon_driverslicense.svg')} /></div>
									<div className="radioTextWrapper">
										<div className="radioBtnHead">B</div>
										<div className="radioBtnTxt">Personbil och lätt lastbil (totalvikt 3500 kg)</div>
									</div>
									<div className="radioBtnInfo"><img onClick={showModalMessage} id="1" src={images('./info_btn.svg')} /></div>
								</label>

								<input type="radio" id="val_kbu" name="driversLicense" onChange={handleFormChange} value="val_kbu" />
								<label htmlFor="val_kbu" className="driverlicenseBtn">
									<div className="radioBtnImg"><img src={images('./icon_driverslicense.svg')} /></div>
									<div className="radioTextWrapper">
										<div className="radioBtnHead">Utökat B</div>
										<div className="radioBtnTxt">Personbil och tyngre släp (totalvikt 4250 kg)</div>
									</div>
									<div className="radioBtnInfo"><img onClick={showModalMessage} id="2" src={images('./info_btn.svg')} /></div>
								</label>

								<input type="radio" id="val_kbe" name="driversLicense" onChange={handleFormChange} value="val_kbe" />
								<label htmlFor="val_kbe" className="driverlicenseBtn">
									<div className="radioBtnImg"><img src={images('./icon_driverslicense.svg')} /></div>
									<div className="radioTextWrapper">
										<div className="radioBtnHead">BE</div>
										<div className="radioBtnTxt">Personbil med tungt släp (3500 kg + 3500 kg)</div>
									</div>
									<div className="radioBtnInfo"><img onClick={showModalMessage} id="3" src={images('./info_btn.svg')} /></div>
								</label>

								<input type="radio" id="val_kbeg" name="driversLicense" onChange={handleFormChange} value="val_kbeg" />
								<label htmlFor="val_kbeg" className="driverlicenseBtn">
									<div className="radioBtnImg"><img src={images('./icon_driverslicense.svg')} /></div>
									<div className="radioTextWrapper">
										<div className="radioBtnHead">BE före 2013</div>
										<div className="radioBtnTxt">Personbil (3500 kg). inga begr. för släp</div>
									</div>
									<div className="radioBtnInfo"><img onClick={showModalMessage} id="4" src={images('./info_btn.svg')} /></div>
								</label>

							</div>
						</div>

						<div className="resultBtns">
							<div className="restartBtnInfo" onClick={resetApp}>
								<div className="restartBtnIcon"><img src={images('./icon_restart.svg')} /></div>
								<div className="restartBtnText">Rensa alla fält</div>
							</div>

							<div className="blueButton large" onClick={viewResult}>Se resultat</div>
						</div>
						{ /* <button type="submit" className="button is-block is-info is-fullwidth">Sök</button> */}

					</div>
				</div>

			</form>

			<div id="resultBlock" style={{display: 'none'}}>
				<div className="resultBlock bgBlue">
					<div className="container-fluid sections">
						<h2>Ditt resultat</h2>

						<Result formVal={formValues} calcVal={calcValues} />
					</div>
				</div>

				<div className="container-fluid bgGrey">
					<div className="row">
						<div className="col-md-6 order-md-2 px-0 bgWhite d-flex align-items-center">
							<Banner bannerTxt={formValues.bannerTxt} bannerId={formValues.bannerId} />
						</div>

						<div className="col-md-6 px-0 d-flex">
							<div className="banner p-0">
								<img src={images('./forester_slap2.jpg')} className="full" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="banner1" className="container-fluid">
				<div className="row">
					<div className="col-md-6 px-0 d-flex align-items-center justify-content-end">
						<div className="banner align-items-start">
							<h1>Säkraste dragbil 2021, enligt Euro NCAP</h1>
							<a href="https://www.subaru.se/modeller/outback/" className="blueButton">Läs mer här</a>
						</div>
					</div>

					<div className="col-md-6 px-0 d-flex">
						<div className="banner p-0">
							<img src={images('./housevan_family.jpg')} className="full" />
						</div>
					</div>
				</div>
			</div>

			<footer>
				<div className="container-fluid">
					<div className="logo large">
						<img src={images('./logo-icon.svg')} />
						<div className="logo-text">Släpkollen<span className="punch-line">En tjänst från <strong>Subaru</strong></span></div>				
					</div>

					<p>Släpkollen presenteras av Subaru, störst på fyrhjulsdrivna dragbilar.</p>

					<p className="dimmed">
						Släpkollen hämtar informationen från transportstyrelsen.se och kan inte garantera att alla uppgifter på sidan stämmer.<br/>
						Vill du ha säkra uppgifter bör du ta kontakt med Transportstyrelsen. Släpkollen drivs av Subaru Nordic AB.<br/>
						Använd dessa länkar för att se hur vi hanterar personuppgifter och cookies. <br/>
						Upplever du problem med sidan, vänligen mejla oss på: <a href="mailto: info@slapkollen.se" className="mail-footer">info@slapkollen.se</a>
					</p>

					<ul className="links">
						<li><a href="https://www.subaru.se/cookies/">Cookies</a></li>
						<li><a href="https://www.subaru.se/privacy-notice/">Villkor</a></li>
					</ul>
				</div>
			</footer>







			<div id="modalMessageId" className="modalMessage">
				<div onClick={() => { closeModalMessage() }} className="modalMessage-content">
					<div className="modalMessage-header">
						<span className="modalCloseBtn">&times;</span>
					</div>
					<div className="modalMessage-body">
						<Modalmessage modalText={formValues.modalTxt} />

					</div>

				</div>
			</div>


			<div className="debugVal">
				<h3>DEBUG VALUES</h3>
				BIL TOTALVIKT:
                         <label>
					<input type="number" name="val_bto" onChange={handleFormChange} value={calcValues.val_bto} />
				</label>
				<br />
				BIL TJÄNSTEVIKT:
                         <label>
					<input type="number" name="val_btj" onChange={handleFormChange} value={calcValues.val_btj} />
				</label>
				<br />
				BIL MAXSLÄPVAGNSVIKT:
                         <label>
					<input type="number" name="val_bsv" onChange={handleFormChange} value={calcValues.val_bsv} />
				</label>
				<br />
				{ /* BIL TÅGVIKT:
                         <label>
                        <input type="number" name="val_btv" onChange={handleFormChange} value={calcValues.val_btv} />
                    </label>
                    <br /> */ }
				SLÄP TOTALVIKT:
                         <label>
					<input type="number" name="val_sto" onChange={handleFormChange} value={calcValues.val_sto} />
				</label>
				<br />
				SLÄP TJÄNSTEVIKT:
                         <label>
					<input type="number" name="val_stj" onChange={handleFormChange} value={calcValues.val_stj} />
				</label>

			</div>




		</div>
	);
};

export default Form;