import React from 'react';

const Banner = (props) => {

	const images = require.context('.././img', true)

    return (
        <div className="banner">
            {/*<div dangerouslySetInnerHTML={bannerTextHtml(props.bannerTxt)} />*/}
            {/*<div>{props.bannerId}</div>*/}

			<p>Ditt resultat presenteras av SUBARU, specialister på fyrhjulsdrivna dragbilar. Läs mer på <a href="https://www.subaru.se">Subaru.se</a></p>

			<img src={images('./subaru-logo.png')} />
        </div>
        )

}

export default Banner;

const bannerTextHtml = (txt) => {
    return { __html: txt };
}
