import React from 'react';

import Form from './components/Form';
import FooBar from './components/FooBar';


import './custom.css';

function App()
{
    const images = require.context('./img', true)


    return (
        <div className="App">

            <div className="topHeader">
				<div className="container">
					<div className="logo lined-up">
						<img src={images('./logo-icon.svg')} />
						<div className="logo-text">Släpkollen<span className="punch-line">En tjänst från <strong>Subaru</strong></span></div>
					</div>
				</div>
            </div>

            <div className="bgWhite">
                <div className="header container">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <h1>Vad får du dra med din bil?</h1>
                        </div>

                        <div className="col-md-7 d-none d-md-block info-text">
                            <p>
                                Här kollar du vilka släp din bil kan dra - eller omvänt, vilken biltyp som krävs för att dra ditt släp. Du kan också se vilken körkortstyp som krävs.
                            </p>
                        </div>
                    </div>
                </div>

            </div>

			{/*<FooBar />*/}

            <Form />
        </div>
    );

}

export default App;
