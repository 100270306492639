const getApiData = (apiData) => {

	let query = Object.keys(apiData)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(apiData[k]))
             .join('&');


	return new Promise(function(resolve, reject)
	{
		fetch('/Api/GetVehicleInfo?' + query,
		{
			method: 'GET', cache: 'no-cache',
			headers:
			{
				'Content-Type': 'application/json'
			}
		}).then(function(response)
		{
			if(response.status === 200)
			{
				resolve(response.text ? response.json() : null);
			}
			else
			{
				reject();
			}
		});
	});
}

export default getApiData;
