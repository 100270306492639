import React from 'react';
import predefinedTrailers from ".././data/predefinedtrailers.json";

const Result = (props) => {

    const images = require.context('.././img', true)

    // console.log(props.calcVal); // calcValues if details about calc should be presented.

    return (
        <div className="result">
			<div className="row align-items-start">
				<div className="resultBox col-md-6">
					{!props.formVal.carLicenseValidated || props.formVal.carLicenseInvalid ? (
						<div className="error">Du har inte angett ett giltigt registreringsnummer för personbil. Vänligen fyll i det och försök igen.</div>
					) : null}

					<div dangerouslySetInnerHTML={resultTextHtml(props.formVal.resultContent)} />


					{/* use to show different response graphics and "hur räknar vi"  */ }
					<div style={{display: 'none'}}>
						<div> responseId={props.formVal.responseId} </div>
						<div> test value:{props.calcVal.val_sto} </div>
						<div> selected trailer:{props.formVal.trailer} </div>
					</div>
				</div>
                               
				<div className="resultGraphics col-md-6">
					<div id="resultTrailerIcon" className={getTrailerColor(props)}><img src={images('./icons_large/' + getIconImage(props))} /></div>
					<div id="resultCarIcon" className={getCarColor(props)}><img src={images('./icons_large/generic_car.svg')} /></div>
				</div>

				<div className="mt-5 col-12">
					<h3><img src={images('./icon_driverslicense.svg')} />Ditt körkort: {getDriversLicense(props.calcVal.driversLicense)}</h3>
				</div>
			</div>

			<div className="resultData row align-items-start">
				<div className="col-md-6">
					<h3>Bil</h3>
					<ul>
						<li>Registreringsnummer: {props.formVal.carLicenseValidated && !props.formVal.carLicenseInvalid ? props.formVal.carLicenseplate : 'Ej angivet'}</li>
						<li>Fabrikat: {props.formVal.carModel}</li>
						<li>Tjänstevikt: {props.calcVal.val_btj ? props.calcVal.val_btj : '-'} kg</li>
						<li>Totalvikt: {props.calcVal.val_bto ? props.calcVal.val_bto : '-'} kg</li>
						<li>Max släpvagnsvikt bromsad släpvagn: {props.calcVal.val_bsv} kg</li>
						<li>Max släpvagnsvikt obromsad släpvagn: {props.calcVal.maxWeightNoBreaks} kg</li>
					</ul>
				</div>

				<div className="col-md-6">
					<h3>Släp</h3>
					<ul>
						<li>Registreringsnummer: <span>{props.formVal.trailerLicenseValidated && !props.formVal.trailerLicenseInvalid ? props.formVal.trailerLicenseplate : 'Ej angivet'}</span></li>
						<li>Fabrikat: {props.formVal.trailerModel}</li>
						<li>Tjänstevikt: <span>{props.calcVal.val_stj ? props.calcVal.val_stj :  '-'} kg</span></li>
						<li>Maxlast: <span>{props.formVal.calculatedTrailerLoad ? props.formVal.calculatedTrailerLoad : '-'} kg</span></li>
						<li>Totalvikt: <span>{props.calcVal.val_sto ? props.calcVal.val_sto : '-'} kg</span></li>
						<li>Bromsad: <span>Ja</span></li>
					</ul>
				</div>
			</div>

			{props.formVal.carLicenseValidated && !props.formVal.carLicenseInvalid ? (			
			<div className="trailerInfo row mt-12">
				<div className="totals col-12">
					<p>Släpkollen berättar vad du får dra samt lasta på släpet. Däremot måste du alltid kolla maximal tågvikt (högsta sammanlagda bruttovikt för bil och släp) på ditt registreringsbevis för att veta hur din bil kan lastas.</p>
				</div>
			</div>) : null}
        </div>
        )
}

export default Result;


const resultTextHtml = (txt) => {
    return { __html: txt };
}

const getIconImage = (props) => {

    const trailers = predefinedTrailers.data;

    const selectedTrailer = trailers.filter(function (trailer) {
        return trailer.trailerid == props.formVal.trailer;
    });

    const selTrailer = selectedTrailer[0]

    if (selTrailer) {
        return (selTrailer.icon)
    } else {
        return 'generic_trailer.svg'; // generic trailer icon
    }
    

}

const getDriversLicense = (key) =>
{
	switch(key)
	{
		case "val_kb":
			return "B";

		case "val_kbu":
			return "Utökat B";

		case "val_kbe":
			return "BE";

		case "val_kbeg":
			return "BE före 2013";

		default:
			return key;
	}
}

const getTrailerColor = (props) => {

    let returnColor = "";

    switch (props.formVal.responseId) {
        case 1:
        case 7:
        case 105:
        case 106:
            returnColor = "Grey";
            break;
        case 2:
        case 4:
        case 6:
            returnColor = "Green";
            break;
        case 101:
        case 102:
        case 103:
        case 107:
        case 108:
            returnColor = "Red";
            break;
        default:
            returnColor = "Grey";
    }

    // check if id 3 not max carriage then orange
    if (props.formVal.responseId === 3) {
        if (props.calcVal.val_sto - props.calcVal.val_stj > props.formVal.calculatedTrailerLoad) {
            returnColor = "Orange";
        } else {
            returnColor = "Green";
        }
    }

    return "resultTrailer" + returnColor;
}

const getCarColor = (props) => {
    let returnColor = "";

    switch (props.formVal.responseId) {
        case 4:
        case 5:
        case 102:
            returnColor = "Grey";
            break;
        case 2:
        case 3:
        case 8:
        case 9:
        case 103:
            returnColor = "Green";
            break;
        case 101:
        case 105:
        case 106:
        case 107:
		case 108:
            returnColor = "Red";
            break;
        default:
            returnColor = "Grey";
    }


    return "resultCar" + returnColor;
}