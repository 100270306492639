import { useState, useEffect } from 'react';
import getApiData from './getApiData';
import calculate from './calculate';
import predefinedTrailers from ".././data/predefinedtrailers.json"
import responseText from ".././data/response.json"

const useForm = (initialFormValues, initialCalcValues, callback) =>
{

	const [formValues, setFormValues] = useState(initialFormValues);

	const [calcValues, setCalcValues] = useState(initialCalcValues);

	// useEffect(() => calculate(values), [values]);

	useEffect(() =>
	{


		// console.log(formValues);

		// set drivers license B checked if not set
		// initial settings

		if(document.formAll.driversLicense.value == "")
		{
			document.formAll.driversLicense.value = "val_kb";

		}


	}, [formValues])

	useEffect(() =>
	{
		async function doCalculate()
		{

			const calcResponse = await calculate(calcValues, formValues);

			showCalculatedResult(calcResponse);
		}
		doCalculate();
	}, [calcValues])

	const [apiData, setApiGetValues] = useState('');

	useEffect(() =>
	{
		// Create async function in the hook to get data from api

		async function getWeightsFromApi()
		{
			try
			{
				if(apiData)
				{
					const apiResponse = await getApiData(apiData);

					console.log('apiResponse:', apiResponse, apiData);

					if(apiData.whatToGet == "car")
					{
						setFormValues(formValues => ({ ...formValues, 'carLicenseValidated': true, 'carModel': apiResponse.model }));
					}
					else
					{
						setFormValues(formValues => ({ ...formValues, 'trailerLicenseValidated': true, 'trailerModel': apiResponse.model }));
					}

					if(apiResponse.registration)
					{
						updateWeights(apiResponse);
					}
					else
					{
						if(apiData.whatToGet == "car")
						{
							setFormValues(formValues => ({ ...formValues, 'carLicenseInvalid': true }));
						}
						else
						{
							setFormValues(formValues => ({ ...formValues, 'trailerLicenseInvalid': true }));
						}						
					}
				}
			}
			catch(ex)
			{
				console.log('apiResponse: error!', ex);
			}

		}
		getWeightsFromApi();
	}, [apiData]);


	const handleMultiOptionsChange = (event) =>
	{
		var val = event.target.value;

		setFormValues(formValues => ({ ...formValues, 'trailerBy': val }));
	};


	const handleSubmit = (event) =>
	{
		if(event) event.preventDefault();
		callback();
	};

	const handleFormChange = (event) =>
	{

		event.persist();

		const whatChanged = event.target.name;
		console.log("whatChanged" + whatChanged);

		switch(whatChanged)
		{
			case 'carLicenseplate':
				const carLicenseplate = event.target.value.toUpperCase()
				event.target.value = carLicenseplate;

				setFormValues(formValues => ({ ...formValues, [event.target.name]: carLicenseplate }));
				setFormValues(formValues => ({ ...formValues, 'carLicenseValidated': false }));
				setFormValues(formValues => ({ ...formValues, 'carLicenseInvalid': false }));

				setCalcValues(calcValues => ({ ...calcValues, 'val_bto': 0 }));
				setCalcValues(calcValues => ({ ...calcValues, 'val_btj': 0 }));
				setCalcValues(calcValues => ({ ...calcValues, 'val_bsv': 0 }));
				setCalcValues(calcValues => ({ ...calcValues, 'val_btv': 0 }));
				setCalcValues(calcValues => ({ ...calcValues, 'maxWeightNoBreaks': 0 }));				

				if(validatedLicensePlate(carLicenseplate))
				{
					setApiGetValues(apiData => ({ ...apiData, 'license': carLicenseplate, 'whatToGet': 'car' }));
				}

				break;

			case 'trailerLicenseplate':

				const trailerLicenseplate = event.target.value.toUpperCase()
				event.target.value = trailerLicenseplate;

				setFormValues(formValues => ({ ...formValues, [event.target.name]: trailerLicenseplate, 'trailerLicenseValidated': false }));
				setFormValues(formValues => ({ ...formValues, 'trailerLicenseInvalid': false }));

				setCalcValues(calcValues => ({ ...calcValues, val_sto: 0, val_stj: 0 }));

				if(validatedLicensePlate(trailerLicenseplate))
				{
					setApiGetValues(apiData => ({ ...apiData, 'license': trailerLicenseplate, 'whatToGet': 'trailer' }));
					resetTrailerRadioBtn();
				}
				break;

			case 'trailer':
				setFormValues(formValues => ({ ...formValues, [event.target.name]: event.target.value }));
				setFormValues(formValues => ({ ...formValues, 'trailerLicenseplate': '' }));

				const v = Number(event.target.value);
				const selectedTrailer = predefinedTrailers.data.filter(trailer => { if(trailer.trailerid === v) return trailer });

				setCalcValues(calcValues => ({ ...calcValues, "val_sto": selectedTrailer[0].sto }));
				setCalcValues(calcValues => ({ ...calcValues, "val_stj": selectedTrailer[0].stj }));

				setFormValues(formValues => ({ ...formValues, "entered_sto": '' }));
				setFormValues(formValues => ({ ...formValues, "entered_stj": '' }));

				break;

			case 'driversLicense':
				setCalcValues(calcValues => ({ ...calcValues, [event.target.name]: event.target.value }));

				break;

			case 'entered_sto':
				resetTrailerRadioBtn();
				setFormValues(formValues => ({ ...formValues, [event.target.name]: event.target.value }));
				setCalcValues(calcValues => ({ ...calcValues, "val_sto": event.target.value }));

				// reset other value if not entered

				if(!formValues.entered_stj)
				{
					setCalcValues(calcValues => ({ ...calcValues, "val_stj": 0 }));
				}

				break;

			case 'entered_stj':
				resetTrailerRadioBtn();
				setFormValues(formValues => ({ ...formValues, [event.target.name]: event.target.value }));
				setCalcValues(calcValues => ({ ...calcValues, "val_stj": event.target.value }));

				// reset other value if not entered

				if(!formValues.entered_sto)
				{
					setCalcValues(calcValues => ({ ...calcValues, "val_sto": 0 }));
				}

				break;

			default:
				setCalcValues(calcValues => ({ ...calcValues, [event.target.name]: event.target.value }));

		}

	};

	const resetTrailerRadioBtn = () =>
	{
		// reset radiobuttons for predefined trailers
		const radioGrp = document.getElementsByName('trailer');
		for(let i = 0; i < radioGrp.length; i++)
		{
			if(radioGrp[i].checked) radioGrp[i].checked = false;
		}
	}

	// validates license plate is either ABC123 or ABC12A
	const validatedLicensePlate = (licenseplate) =>
	{
		if(licenseplate.length === 6)
		{
			const stdPlate = new RegExp("[A-Z][A-Z][A-Z][0-9][0-9][0-9]");
			const stdNewPlate = new RegExp("[A-Z][A-Z][A-Z][0-9][0-9][A-Z]");
			if(stdPlate.test(licenseplate) || stdNewPlate.test(licenseplate))
			{
				return true
			}
		}
	}


	const updateWeights = (weights) =>
	{
		if(weights)
		{
			Object.entries(weights).map(([key, value]) =>
			{
				setCalcValues(calcValues => ({ ...calcValues, [key]: value }));
			})
		}
	}

	const showCalculatedResult = (resp) =>
	{
		setFormValues(formValues => ({ ...formValues, 'resultContent': resp[0] }));
		setFormValues(formValues => ({ ...formValues, 'bannerTxt': resp[1] }));
		setFormValues(formValues => ({ ...formValues, 'bannerId': resp[2] }));
		setFormValues(formValues => ({ ...formValues, 'responseId': resp[3] }));
		setFormValues(formValues => ({ ...formValues, 'calculatedTrailerLoad': resp[4] }));
		setFormValues(formValues => ({ ...formValues, 'calculatedSubaruLoad': resp[5] }));
	}

	const showModalMessage = (evt) =>
	{

		evt.preventDefault();
		evt.stopPropagation();

		const id = Number(evt.target.id);

		const modalWin = document.getElementById('modalMessageId');

		window.addEventListener('click', function(e)
		{
			if(e.target == modalWin)
			{
				modalWin.style.display = "none"
			};
		});

		if(modalWin)
		{
			modalWin.style.display = "block";
			const resp = responseText.modalInfo.filter(item => { if(item.id === id) return item });
			let respTxt = resp[0].text;
			setFormValues(formValues => ({ ...formValues, 'modalTxt': respTxt }));
		}

	}

	const resetApp = () =>
	{
		setFormValues(formValues => (initialFormValues));
		setCalcValues(calcValues => (initialCalcValues));
		resetTrailerRadioBtn();
		document.formAll.driversLicense.value = "val_kb";

		document.getElementById('resultBlock').style.display = "none";
		document.getElementById('banner1').className = "container-fluid";

		document.formAll.scrollIntoView({ behavior: 'smooth', block: 'start' });

	}

	const viewResult = () =>
	{
		const resultBlock = document.getElementById('resultBlock');

		resultBlock.style.display = "block";
		document.getElementById('banner1').className = "container-fluid bgWhite";

		resultBlock.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	return {
		handleMultiOptionsChange,
		handleFormChange,
		handleSubmit,
		formValues,
		calcValues,
		showModalMessage,
		resetApp,
		viewResult
	}
};

export default useForm;
